@import './var.scss';
@import './mixin.scss';

.pareja_items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    //width: 30.33%;
    padding-bottom: 0.8vw;
   
}
.pareja_items.activa{
    cursor: pointer;
}

.icon {
    color: $naranja;
    font-size: 2vw;
    line-height: 80%;

}

.iconimg {
    width: 1.8vw;
    img {
        display: inline-block;
       
    }

}

.hvimg{
   

}
//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .pareja_items {
       // width: 50%;
    }
    .iconimg {
        width: 1.8rem;    
    }

}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {}

// tablet MD
@media (max-width: 768px) {
  
    .iconimg {
        width: 1.2rem;    
    }
}

//only phone
@media (max-width: 576px) {
    .icon {
        font-size: 2rem;
    }

    .nombre {
        font-size: 1rem;
    }

    .pareja_items {
       // width: 50%;
    }

    .iconimg {
        width: 1.5rem;    
    }

    .texto{
       max-width: 1rem;
    }

}