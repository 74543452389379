@import './var.scss';
@import './mixin.scss';

.formSelector {
    .items {
        position: relative;
        padding-bottom: 0vw;
    }

    input[type="radio"] {
        display: none;
        /* Oculta el radio botón original */
    }
}

.label_boton {
    display: inline-block; 
    cursor: pointer;
    position: relative;
    margin-right: 0.6vw;
    font-size: 1.250vw;
    padding: 0.9vw 1.2vw;
    color: $gris;
    width: 80%;
    text-transform: none;

}

input[type="radio"]:checked+.label_boton {
    background-color: $gris;
    /* Estilo para cuando el botón está seleccionado */
    color: #fff;
    border-radius: 3.66vw;
}

//big desktop
@media (min-width: 1920px) {}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .label_boton {
        font-size: 0.9rem;
        padding: 0.9rem 1.2rem;
        width: 100%;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .label_boton {
        font-size: 0.9rem;
        padding: 0.9rem 1.2rem;
        width: 100%;
    }
}

//only sm
@media (max-width: 767px) {
    .label_boton {
        font-size: 0.8rem;
        padding: 0.9rem 1.2rem;
        width: 60%;
    }
}

//only phone
@media (max-width: 576px) {
    .label_boton {
        font-size: 0.9rem;
        padding: 0.9rem 1.2rem;
        width: 100%;
    }
}